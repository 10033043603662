import axios from "axios"
import { AUTH_SESSION_LIST_FAIL, AUTH_SESSION_LIST_REQUEST, AUTH_SESSION_LIST_SUCCESS, CLEAR_ALL_COOKIES } from "../constants/authConstants"
import service from "../services/service"
import Cookies from 'js-cookie';

export const authDetails = () => async (dispatch) => {
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

    try {
        dispatch({ type: AUTH_SESSION_LIST_REQUEST })

        const { data } = await axios({
            url: service.get_sessiondetails,
            method:'get',
            headers: { Authorization: tokenKey },
          })

        dispatch({
            type: AUTH_SESSION_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: AUTH_SESSION_LIST_FAIL,
            payload: error.response && error.response.data
                ? error.response.data
                : error.message,
        })
    }
}




export function clearAllCookies() {
  Cookies.remove('sessionid');
  Cookies.remove('csrftoken');
  sessionStorage.clear()
  return { type: CLEAR_ALL_COOKIES };
}

