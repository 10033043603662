import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import service from "../../../../services/service";
import Loader from "../../../../components/Loader";
import empty from "is-empty";
import Select from "react-select";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import CustomModal from "../../../../components/CustomModal";

const CountryForm = (props) => {
  let navigate = useNavigate();
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  let location = useLocation();

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  let propsState = location.state;
  console.log(service.country_create + "/", propsState);
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [editable, setEditable] = useState(propsState?.editMode ? false : true);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  let toastAttr = {
    theme: "colored",
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Dashboard",
      href: "/admin/dashboard",
    },
    {
      title: "Country",
      href: "/admin/masters/country",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Country Details",
      id: "details",
      show: true,
    },
  ]);

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };
  const toggle = () => {
    setEditable(!editable);
  };

  const renderListView = () => {
    return navigate("/admin/masters/country");
  };

  const findErrors = () => {
    const {
      country_name,
      country_code,
      currency,
      terminal_id,
      mobile_prefix,
      mobile_digits,
      username,
      password,
      client_id,
      client_secret,
    } = form;
    const newErrors = {};

    if (empty(country_name))
      newErrors.country_name = "This is a required field";
    if (empty(country_code))
      newErrors.country_code = "This is a required field";
    if (empty(currency)) newErrors.currency = "This is a required field";
    if (empty(terminal_id)) newErrors.terminal_id = "This is a required field";
    if (empty(mobile_prefix))
      newErrors.mobile_prefix = "This is a required field";
    if (empty(mobile_digits))
      newErrors.mobile_digits = "This is a required field";
    if (empty(username)) newErrors.username = "This is a required field";
    if (empty(password)) newErrors.password = "This is a required field";
    if (empty(client_id)) newErrors.client_id = "This is a required field";
    if (empty(client_secret))
      newErrors.client_secret = "This is a required field";
    return newErrors;
  };
  // const handleSelect = (keyName, setFn, e) => {
  //   if (keyName === "city") {
  //     let selCity = cityData.filter((obj) => (obj.id = e && e.value));
  //     setState(selCity.state_name);
  //   }
  //   setFn(e);
  //   setForm({
  //     ...form,
  //     [keyName]: (e && e.value) || null,
  //   });
  //   if (keyName && !!errors[keyName])
  //     setErrors({
  //       ...errors,
  //       [keyName]: null,
  //     });
  // };

  const saveForm = () => {
    let id = editMode ? propsState.id : null,
      method = editMode ? "put" : "post";
    console.log(editMode, "mode");
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
          ? service.country_create + form.id + "/update/"
          : service.country_create,
        data = JSON.parse(JSON.stringify(form));
      // data.country_id = String(form.id);
      console.log("-----------");
      axios({
        url: url,
        method: method,
        headers: { Authorization: tokenKey },
        data: data,
      })
        .then((res) => {
          if (editMode) setForm(res.data);
          console.log(res);
          if (method == "post") {
            toast.success("Created successfully", toastAttr);
            setTimeout(navigate(-1), 1000);
          }
          if (method === "put") {
            toast.success("Updated successfully", toastAttr);
            setTimeout(navigate(-1), 1000);
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          if (err?.response?.status === 500) {
            setShow(true)
            setBody(err.response.data)
          } else if (err?.response?.status === 400) {
            setShow(true)
            setBody(err.response.data.message)
          } else {
            setShow(true)
            setBody(err.response.data.message)
          }
        });
    }
  };

  useEffect(() => {
    // axios.get(service.country_create, { headers: tokenKey }).then((res) => {
    //   let city = [],
    //     state = [];
    //   res.data.map((obj) => {
    //     city.push({ value: obj.id, label: obj.name });
    //   });
    //   setCityList(city);
    //   setcityData(res.data);
    console.log(propsState);
    if (propsState && propsState.editMode) {
      // {axios
      //   .get(service.country_create + propsState.id + "/", {
      //     headers: tokenKey,
      //   })
      //   .then((resp) => {

      //     setForm(resp.data.results);
      //     setLoader(false);
      //   })
      //   .catch((err) => {
      //     setLoader(false);
      //   });}

      axios({
        url: service.country_create + propsState.id + "/",
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setForm(res.data);
          console.log(res);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          if (err?.response?.status === 500) {
            setShow(true)
            setBody(err.response.data)
          } else if (err?.response?.status === 400) {
            setShow(true)
            setBody(err.response.data.message)
          } else {
            setShow(true)
            setBody(err.response.data.message)
          }
        });
    } else setLoader(false);
    // });
  }, []);

  return (
    <Loader loading={loading} overlay={overlay}>
      <Container className="vendor">
        <Row>
          <Col md={12} className="contBody">
            <Card className="country__list">
              <CardBody className="theme-light">
              <Row className="d-flex justify-content-between">
                <Col md={4}>
                  <h3 className="pt-3 px-3 text-primary">Country</h3>
                </Col>
                
                <Col md={4}>
                  <div className="pt-3">

            <Breadcrumbs data={breadcrumbData} />
                  </div>

                </Col>
                <Col md={4}>
                  
                </Col>
              </Row>
              <hr/>
                <Row className="formBtnsDiv mb-3">
                  <Col md={4}>
                    <Button
                      variant="primary"
                      className="text-grad"
                      disabled={!editable}
                      onClick={(e) => {
                        saveForm();
                      }}
                    >
                      {editMode ? "Update" : "Save"}
                    </Button>
                    <Button
                      className="ms-2"
                      variant="secondary"
                      onClick={(e) => {
                        renderListView();
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col md={4}></Col>
                  <Col md={3} className="d-flex justify-content-end" hidden={!propsState?.editMode}>
                    <div className="mx-3">
                    <Button
                      className="btn-grad-primary"
                      variant="primary"
                      onClick={(e) => {
                        toggle();
                      }}
                    >
                      Edit
                    </Button>
                    </div>
                  </Col>
                  <Col md={1}></Col>
                </Row>
                <Row className="formDiv">
                  <Col md={12}>
                    {/* <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav> */}

                    <div className="tabContent">
                      <Form className="form defaultForm">
                        {tab.details ? (
                          <Row>
                            <Col md={12}>
                              <Row className="py-3">
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Country Name
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        readOnly={!editable}
                                        type="text"
                                        name="country_name"
                                        className="input-border"
                                        value={form.country_name}
                                        onChange={(e) =>
                                          handleInput(
                                            "country_name",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.country_name}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.country_name}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Country Code
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        readOnly={!editable}
                                        type="text"
                                        name="country_code"
                                        className="input-border"
                                        value={form.country_code}
                                        onChange={(e) =>
                                          handleInput(
                                            "country_code",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.country_code}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.country_code}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Currency
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        readOnly={!editable}
                                        type="text"
                                        name="currency"
                                        className="input-border"
                                        value={form.currency}
                                        onChange={(e) =>
                                          handleInput(
                                            "currency",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.currency}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.currency}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Terminal ID
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        readOnly={!editable}
                                        type="text"
                                        name="terminal_id"
                                        className="input-border"
                                        value={form.terminal_id}
                                        onChange={(e) =>
                                          handleInput(
                                            "terminal_id",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.terminal_id}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.terminal_id}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Mobile Prefix
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        readOnly={!editable}
                                        type="text"
                                        name="mobile_prefix"
                                        className="input-border"
                                        value={form.mobile_prefix}
                                        onChange={(e) =>
                                          handleInput(
                                            "mobile_prefix",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.mobile_prefix}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.mobile_prefix}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Mobile Digits
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="mobile_digits"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.mobile_digits}
                                        onChange={(e) =>
                                          handleInput(
                                            "mobile_digits",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.mobile_digits}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.mobile_digits}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Username
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="username"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.username}
                                        onChange={(e) =>
                                          handleInput(
                                            "username",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.username}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.username}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Password
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="password"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.password}
                                        onChange={(e) =>
                                          handleInput(
                                            "password",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.password}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Client ID
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="client_id"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.client_id}
                                        onChange={(e) =>
                                          handleInput(
                                            "client_id",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.client_id}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.client_id}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={6} className="d-grid gap-3">
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Client Secret
                                        <span className="text-danger">*</span>
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="client_secret"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.client_secret}
                                        onChange={(e) =>
                                          handleInput(
                                            "client_secret",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.client_secret}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.client_secret}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Email Subject
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="email_subject"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.email_subject}
                                        onChange={(e) =>
                                          handleInput(
                                            "email_subject",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.email_subject}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Address
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="address"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.address}
                                        onChange={(e) =>
                                          handleInput("address", e.target.value)
                                        }
                                        isInvalid={!!errors.address}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        City
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="city"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.city}
                                        onChange={(e) =>
                                          handleInput("city", e.target.value)
                                        }
                                        isInvalid={!!errors.city}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Transaction No
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="trn_no"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.trn_no}
                                        onChange={(e) =>
                                          handleInput("trn_no", e.target.value)
                                        }
                                        isInvalid={!!errors.trn_no}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.trn_no}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Minimum Amount
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="number"
                                        name="min_amount"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.min_amount}
                                        onChange={(e) =>
                                          handleInput(
                                            "min_amount",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.min_amount}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Maximum Amount
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="number"
                                        name="max_amount"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.max_amount}
                                        onChange={(e) =>
                                          handleInput(
                                            "max_amount",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.max_amount}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label className="mt-2">
                                        Multiple Of
                                      </Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        type="text"
                                        name="multiples_of"
                                        className="input-border"
                                        readOnly={!editable}
                                        value={form.multiples_of}
                                        onChange={(e) =>
                                          handleInput(
                                            "multiples_of",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.multiples_of}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CustomModal show={show} body={Body} handleClose={handleClose} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Container>
    </Loader>
  );
};

export default CountryForm;
