import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import { Button,InputGroup } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import service from "../../../../services/service";
import DataTable from "react-data-table-component";
import Loader from "../../../../components/Loader";
import CustomModal from "../../../../components/CustomModal";

const Country = (props) => {

  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");
  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  let navigate = useNavigate();
  let location = useLocation();
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  let propsState = location.state;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Dashboard",
      href: "/admin/dashboard",
    },
    {
      title: "Country",
      href: "#",
    },
  ];
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");


  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "18px",
        justifyContent: "flex-start",
      },
    },
    rows: {
      style: {
        fontSize: "18px",
        minHeight: "52px",
      },
    },
  };

  const renderForm = (row) => {
    if (row)
      return navigate({
        pathname: "/admin/masters/countryForm",
        // state: {
        //   id: row.id,
        //   editMode: true,
        // },
        state: "",
      });
    else
      return navigate({
        pathname: "/admin/masters/countryForm",
        state: {
          // vendor_id : propsState.id,
        },
      });
  };
  
  useEffect(() => {
    countryList();
  }, [search, page, perPage]);

  const countryList = () => {
    let data = {};
    if (search.length > 0) {
      data.search = search;
    }
    setLoader(true)
    axios({
      url: service.country_list,
      method: "get",
      headers: { Authorization: tokenKey },
      params: data,
    })
      .then((res) => {
        settableData(res.data.results);
        console.log(res);
    setLoader(false)

      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
  };
  // const columns = [{
  //   dataField: 'name',
  //   text: 'Area Name'
  // }, {
  //   dataField: 'description',
  //   text: 'Description'
  // }];
  const columns = [
    {
      id: 1,
      name: "Country Name",
      selector: (row) => row.country_name,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Country Code",
      selector: (row) => row.country_code,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      // right: true,
      reorder: true,
    },
    {
      name: "Actions",
      
      cell: (row) => (
        <>
          <span
            onClick={() => {
              console.log(row.id);
              return navigate("/admin/masters/countryForm", {
                state: {
                  id: row.id,
                  editMode: true,
                },
              });
            }}
            className="btn btn-grad-primary"
          >
            View
          </span>
          
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleButtonClick = (e, id) => {
    console.log("Row Id", id);
  };
  return (

    <div className="px-5">
      <div>
        <div className="pageNav">
          <div className="bold-text">
            {/* <Breadcrumbs data={breadcrumbData} /> */}
          </div>
        </div>
      </div>
      {/* <Container className="country"> */}
      <Row>
        <Col md={12} className="contBody">
          <Card className="country__list">
            <>
              <Row className="d-flex justify-content-between">
                <Col md={4}>
                  <h3 className="pt-3 px-3 text-primary">Country</h3>
                </Col>
                {/* <Col md={4}></Col>
                <Col md={2}></Col> */}
                {/* <Col > */}
                <Col md={4}>
                  <div className="pt-3">

            <Breadcrumbs data={breadcrumbData} />
                  </div>

                </Col>
                {/* <Col md={2}></Col> */}
                <Col md={3} className="d-flex justify-content-end">
                  <Button
                    className="mt-3 btn-grad-primary"
                    onClick={(e) => renderForm()}
                  >
                    Create
                  </Button>
                </Col>
                <Col md={1}></Col>
              </Row>
            </>
            <hr />
            <CardBody>
              <Row className="d-flex justify-content-between align-content-center formBtnsDiv mar-b-10 mt-3 mb-3">
                <Col md={4}>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      style={{ width: "80%" }}
                    />
                  </div>
                </Col>
                <Col md={6}>

                </Col>
                <Col md={2}>
                  <InputGroup className="mb-3 w-100">
                    <Form.Control
                      placeholder="Go to page"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      id="searchPage"
                    />
                    <Button
                      variant="outline-primary"
                      id="button-addon2"
                      onClick={() => {
                        setPage(document.getElementById("searchPage").value);
                      }}
                    >
                      go
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
    <Loader loading={loading} overlay={overlay}>

              <Row className="countryList">
                <DataTable
                  columns={columns}
                  data={tableData}
                  defaultSortFieldId={1}
                  customStyles={customStyles}
                  pagination
                  // selectableRows
                  // onRowSelected={this.handleChange}
                  // onRowSelected={console.log("this muthu da")}
                />
              </Row>
    </Loader>

            </CardBody>
          </Card>
        </Col>
      </Row>
      <CustomModal
                            show={show}
                            body={Body}
                            handleClose={handleClose}
                          />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* </Container> */}
    </div>
  );
};
export default Country;
