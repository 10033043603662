import React, { useState, useEffect, createRef } from "react";
import { Card, Form, Modal, Button, ModalHeader, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import empty from "is-empty";
import OtpInput from "react-otp-input";
import CloseButton from "react-bootstrap/CloseButton";

import "./common.css";
import service from "../services/service";
import Header from "../components/Header";
import { authDetails, clearAllCookies } from "../actions/authActions";
import CustomModal from "../components/CustomModal";
import Loader from "../components/Loader";
import { InputGroup } from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { Input } from "reactstrap";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const kalyan_bg = `${process.env.PUBLIC_URL}/images/kalyan_bg.png`;
const kalyan_logo = `${process.env.PUBLIC_URL}/images/kalyan_logo.png`;

function containsSpecialCharacters(str) {
  var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
  return regex.test(str);
}

function Login() {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const [loading, setloading] = useState(false);
  const [overlay, setoverlay] = useState(false);

  const [loading1, setloading1] = useState(false);
  const [overlay1, setoverlay1] = useState(false);

  const authDetail = useSelector((state) => state.authDetail);
  const { loading: detailLoading, error, details } = authDetail;

  const [showModal, setShowModal] = useState(false);
  const [otp_, setOtp_] = useState("");

  const [otplist, setOtpList] = useState([null, null, null, null, null, null]);

  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");

  const [showotp, setShowotp] = useState(true);

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  let dispatch = useDispatch();

  const [otp_type, setOtpType] = useState("");

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const [copyValue, setCopyValue] = useState("");

  const [view, setView] = useState(false);

  const [isActive, setIsActive] = useState(false);

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(/=|;/);
      cookieValue = decodeURIComponent(cookies[1]);
    }
    return cookieValue;
  }
  var csrftoken = getCookie("csrf_token");
  var header = {};
  if (csrftoken != null) {
    header["X-CSRFToken"] = csrftoken;
  }

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  const toggleModal = (close) => {
    // alert(close)
    if (close == "close") {
      setEmail("");
    }
    setShowModal(!showModal);
    setOtp_("");
  };

  const verifyOTP = (e) => {
    // let otp =
    //   otplist[0] +
    //   otplist[1] +
    //   otplist[2] +
    //   otplist[3] +
    //   otplist[4] +
    //   otplist[5];
    console.log(
      document.getElementById("otp").value,
      "document.getElementById('otp')"
    );
    let data = {
      email: email1,
      otp_type: otp_type,
      otp: document.getElementById("otp").value,
    };
    setLoader1(true)
    axios({
      url: service.otp_verify,
      method: "post",
      data: data,
      headers: { ...header },

    })
      .then((res) => {
        sessionStorage.setItem("tokenkey", res.data.token);
        dispatch(authDetails());

        if (otp_type == "login_verify") {
          if (res.data.user_type === "internal") {
            toast.success("Verified successfully", toastAttr);
            setTimeout(() => {
              navigate("/admin/dashboard");
            }, 1000);
          } else if (res.data.user_type === "customer") {
            toast.success("Verified successfully", toastAttr);
            setTimeout(() => {
              navigate("/giftcardregistered");
            }, 1000);
          }
        } else {
          setShowPasswordModal(true);
        }
        setEmail('')
        setShowModal(false);
        setLoader1(false);
      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
  };

  const ResendOTP = () => {
    setLoader1(true);
    console.log(loading1,"loading",overlay1,'over')
    axios({
      url: service.otp_resend,
      method: "post",
      data: { email: email1 },
      headers: { ...header },

    })
      .then((res) => {
        
        setShowotp(true);
        setLoader1(false);
        // OTPInput()
      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
    // setLoader(false);
    // }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header className="bg-primary text-white" as="h3">Password criteria</Popover.Header>
      <Popover.Body>
      <div className="">
                <ul><li>Password should be minimum 8 characters</li><li>Password should contain atleast 1 numeric, 1 alphabet, 1 special character </li></ul>
              </div>
      </Popover.Body>
    </Popover>
  );

  const passwordValidate = (e) => {
    let str = newPassword1;

    setIsActive(false);
    var spl = containsSpecialCharacters(str);
    if (
      str.length < 8 ||
      spl === false ||
      str.length > 50 ||
      str.search(/\d/) == -1 ||
      str.search(/[a-z]/) == -1 ||
      str.search(/[A-Z]/) == -1
    ) {
      setShow(true);
      setBody("Password does not match the given creteria");
      return;
    }
  };

  const ChangePassword = () => {
    if (newPassword1 != newPassword2) {
      setShow(true);
      setBody("Password Should Be Same");
    } else {
      axios({
        url: service.forgotpassword,
        method: "post",
        data: {
          email: email1,
          new_password1: newPassword1,
          new_password2: newPassword2,
        },
        headers: { ...header },

      })
        .then((res) => {
          sessionStorage.setItem("tokenkey", res.data.token);
          dispatch(authDetails());
          
          setShowPasswordModal(false);
          toast.success("Password Changed successfully", toastAttr);

          console.log(res);
        })
        .catch((err) => {
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          if (err?.response?.status === 500) {
            setShow(true)
            setBody(err.response.data)
          } else if (err?.response?.status === 400) {
            setShow(true)
            setBody(err.response.data.message)
          } else {
            setShow(true)
            setBody(err.response.data.message)
          }
        });
    }
  };

  const handleInput = (keyName, value) => {
    console.log(keyName, value);

    if (keyName == "username") {
      setEmail(value);
      setEmail1(value);
    }
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const findErrors = () => {
    const { username, password } = form;
    const newErrors = {};

    if (empty(username)) newErrors.username = "This is a required field";
    if (empty(password)) newErrors.password = "This is a required field";
    return newErrors;
  };

  function loginHandler(event) {
    event.preventDefault();
    // console.log(event)
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = service.user_login,
        data = JSON.parse(JSON.stringify(form));

      console.log("-----------");

      

      axios({
        url: url,
        method: "post",
        data: data,
        headers: { ...header },
      })
        .then((res) => {
          // if (editMode) setForm(res.data);
          console.log(res.data);
          console.log(res.data.otp_verify_required);
          if (res.data.otp_verify_required) {
            toggleModal();
            setLoader(false);
            setShowotp(true);
            // OTPInput()
            setOtpType("login_verify");
            return;
          }

          sessionStorage.setItem("tokenkey", res.data.token);
          dispatch(authDetails());

           if (res.data.user_type === "customer") {
            toast.success("Login successfully", toastAttr);
            setTimeout(() => {
              navigate("/giftcardregistered");
            }, 1000);
          }else{
            toast.success("Not an Valid User", toastAttr);

            sessionStorage.clear()
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          if (err?.response?.status === 500) {
            setShow(true)
            setBody(err.response.data)
          } else if (err?.response?.status === 400) {
            setShow(true)
            setBody(err.response.data.message)
          } else {
            setShow(true)
            setBody(err.response.data.message)
          }
        });
    }
  }
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);

  };
  const setLoader1 = (val) => {
    setloading1(val);
    setoverlay1(val);
  };
  let toastAttr = {
    theme: "colored",
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const navigate = useNavigate();

  const signupHandler = () => {
    return navigate("/sign_up");
  };

  useEffect(() => {
    if (sessionStorage.getItem("tokenkey") && details) {
      if (details.user_type === "internal") {
        navigate("/admin/dashboard");
      } else if (details.user_type === "customer") {
        navigate("/giftcardregistered");
      }
    }
  }, [details]);

  const forgotPassword = () => {
    toggleModal();
    setOtpType("forget_verify");
    setShowotp(false);
  };

  const EnterOTP = (props) => {
    // const numerOfInputs = props?.numerOfInputs || 6;

    // const [inputRefsArray] = useState(() =>
    //   Array.from({ length: numerOfInputs }, () => createRef())
    // );
    // const [currentIndex, setCurrentIndex] = useState(0);

    // const [lettersOTP, setLettersOTP] = useState(() =>
    //   Array.from({ length: numerOfInputs }, () => "")
    // );

    // let handleKeyPress = (event) => {
    //   const text = navigator.clipboard.readText().then((text1) => {
    //     setCopyValue(text1);
    //   });
    //   console.log(copyValue, "reererugegrevev", window.Clipboard);

    //   setCurrentIndex((prevIndex) => {
    //     if (!prevIndex) {
    //       prevIndex = 0;
    //     }

    //     if (event.key == "Backspace") {
    //       var nextIndex = prevIndex < numerOfInputs + 1 ? prevIndex - 1 : 0;
    //     }else{
    //       nextIndex =
    //       prevIndex < numerOfInputs - 1 ? prevIndex + 1 : numerOfInputs - 1;
    //     }

    //     const nextInput = inputRefsArray?.[nextIndex]?.current;
    //     if (nextInput) {
    //       nextInput.focus();
    //       nextInput.select();
    //       return nextIndex;
    //     }
    //   });
    // };

    // const Paste = () => {
    //   let otp__ = otplist;
    //   otp__[0] = copyValue[0];
    //   otp__[1] = copyValue[1];
    //   otp__[2] = copyValue[2];
    //   otp__[3] = copyValue[3];
    //   otp__[4] = copyValue[4];
    //   otp__[5] = copyValue[5];
    //   console.log(otp__, "ooooooooooo");
    //   setLettersOTP(otp__);
    //   setOtpList(otp__);
    // };

    // useEffect(() => {
    //   if (inputRefsArray?.[0]?.current) {
    //     inputRefsArray?.[0]?.current?.focus();
    //   }

    //   window.addEventListener("keyup", handleKeyPress, false);
    //   // window.addEventListener("paste", Paste, false);
    //   return () => {
    //     window.removeEventListener("keyup", handleKeyPress);
    //     // window.addEventListener("paste", Paste);
    //   };
    // }, []);

    if (showotp == true) {
      return (
        <>
          <div className="d-flex justify-content-center mt-5 fw-bold">
            Enter OTP
          </div>
          <div className="d-flex justify-content-center">
            {/* {inputRefsArray.map((ref, index) => {
              return (
                <input
                  className="form-control-m form-control-solid-m ms-3 mt-3"
                  ref={ref}
                  type="text"
                  id={`box${index}-1`}
                  onChange={(e) => {
                    const { value } = e.target;
                    console.log(index, "IIIIIIIIIIIIIIIIIII");
                    setLettersOTP((letters) =>
                      letters.map((letter, letterIndex) =>
                        letterIndex === index ? value : letter
                      )
                    );
                    let otp__ = otplist;
                    otp__[index] = value;
                    setOtpList(otp__);

                    console.log(otp_, "OTPTOTPTOTP1111111111111111111");
                  }}
                  onClick={(e) => {
                    setCurrentIndex(index);
                    e.target.select();
                  }}
                  value={lettersOTP[index]}
                  maxLength={1}
                />
              );
            })} */}
            <Col md={3}>
              <Input
                className="mt-3 form-control text-center"
                id="otp"
                maxLength={6}
                type="text"
              />
            </Col>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button className="btn btn-link" onClick={ResendOTP}>
              Resend OTP
            </button>
          </div>
        </>
      );
    } else {
      return (
        <div className="d-flex justify-content-center mt-3 fw-bold">
          <button
            className="btn btn-success"
            onClick={ResendOTP}
            disabled={!email}
          >
            Send OTP
          </button>
        </div>
      );
    }
  };

  return (
    <Loader loading={loading} overlay={overlay}>
      <div
        className="login__signup__background"
        style={{ backgroundImage: `url(${kalyan_bg})` }}
      >
        <div className="d-flex flex-row justify-content-end">
              <button
                className="btn btn-link text-muted"
                
              >
                <Link style={{color:"whitesmoke"}} to="/termsandcondition">Terms & Conditions</Link>
              </button>
            </div>
        <Card className="login__signup__card">
          <div className="d-flex justify-content-center my-3">
            <img src={kalyan_logo} className="kalyan__logo" alt="kalyan_logo" />
          </div>
          <Card.Body>
            <Form.Control
              type="text"
              id="username"
              className="form-control mt-5 fs-5"
              placeholder="Email / Mobile number"
              onChange={(e) => handleInput("username", e.target.value)}
              isInvalid={!!errors.username}
              onKeyUp={(event) => {
                if (event.key == "Enter") {
                  document.getElementById("password").focus();
                }
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.username}
            </Form.Control.Feedback>

            <Form.Control
              id="password"
              className="form-control mt-5 fs-5"
              type="password"
              placeholder="Enter password"
              onChange={(e) => handleInput("password", e.target.value)}
              isInvalid={!!errors.password}
              onKeyUp={(event) => {
                if (event.key == "Enter") {
                  document.getElementById("login").focus();
                }
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>

            <div className="d-flex flex-row justify-content-end mt-4">
              <button
                className="fw-bold btn btn-link text-grad-primary"
                onClick={forgotPassword}
              >
                Forgot Password?
              </button>
            </div>

            <button
              id="login"
              className="btn btn-primary form-control mt-3 fs-5 text-white"
              onClick={loginHandler}
            >
              Login
            </button>

            <div className="hr-sect mt-4">Or</div>
            <button
              className="btn btn-success form-control mt-3 fs-5"
              onClick={signupHandler}
            >
              Sign Up
            </button>
          </Card.Body>
        </Card>
        <div>
        
          <Modal
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="modalotpheader">
              <div className="d-flex justify-content-between">
                <span className="fw-bold fs-5">Verify OTP</span>
              </div>
              <button
                className="btn btn-link"
                onClick={() => toggleModal("close")}
              >
                <CloseButton variant="white" />
              </button>
            </Modal.Header>
            <Modal.Body>
            <Loader loading={loading1} overlay={overlay1}>
                <div className="row d-flex justify-content-center fw-bold">
                  Email
                </div>

                <div className="d-flex justify-content-center">
                  <Col md={5}>
                    <Form.Control
                      type="text"
                      className="form-control mt-3 fs-6"
                      placeholder="Enter Registered Email"
                      onChange={(e) => handleInput("username", e.target.value)}
                      value={email}
                      disabled={showotp}
                    />
                  </Col>
                </div>

                <EnterOTP />
                </Loader>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => toggleModal("close")}
              >
                Close
              </button>
              <button className="btn btn-primary" onClick={verifyOTP}>
                Verify
              </button>
            </Modal.Footer>
          </Modal>
          
        </div>
        <CustomModal show={show} body={Body} handleClose={handleClose} />

        <Modal
          show={showPasswordModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="modalotpheader">
            <div className="d-flex justify-content-between">
              <span className="fw-bold fs-5">Change Password</span>
            </div>
            <button
              className="btn btn-link"
              onClick={() => setShowPasswordModal(false)}
            >
              <CloseButton variant="white" />
            </button>
          </Modal.Header>
          {/* <Modal.Header
            closeButton
            onClick={() => setShowPasswordModal(false)}
          ></Modal.Header> */}
          <Modal.Body>
          <OverlayTrigger trigger="click" show={isActive} placement="top" overlay={popover}>
            <InputGroup className="mb-3">
              <Form.Control
                className="form-control mt-4 fs-5"
                value={form.password}
                type={view ? "text" : "password"}
                placeholder="Enter Password"
                onFocus={handleFocus}
                onBlur={(e) => passwordValidate(e)}
                onChange={(e) => setNewPassword1(e.target.value)}
              />
              <InputGroup.Text
                className=" mt-4 fs-5"
                id="basic-addon1"
                onClick={() => setView(!view)}
              >
                {view ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </InputGroup.Text>
            </InputGroup>
          </OverlayTrigger>
            {/* <Form.Control
              className="form-control mt-5 fs-5"
              type="password"
              placeholder="Enter New password"
              onChange={(e) => setNewPassword1(e.target.value)}
              onBlur={(e) => passwordValidate(e)}
            /> */}

            

            <Form.Control
              className="form-control mt-5 fs-5"
              type="password"
              placeholder="Confirm New password"
              onChange={(e) => setNewPassword2(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setShowPasswordModal(false)}
            >
              Close
            </button>
            <button
              className="btn btn-success"
              onClick={() => ChangePassword()}
              disabled={!newPassword1 || !newPassword2}
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Loader>
  );
}

export default Login;
