import config from "../config"

let domain = config.service

export default{
    country_create : domain + "/paymentauth/country/",
    country_list : domain + "/paymentauth/country/list/",
    scheme_create : domain + "/payment/scheme/",
    scheme_list : domain + "/payment/scheme/list/",
    country_details : domain + "/paymentauth/country_details/",
    user_signup : domain + "/paymentauth/signup/",
    user_login : domain + "/paymentauth/login/",
    admin_login : domain + "/paymentauth/adminlogin/",
    
    user_logout : domain + "/paymentauth/logout/",
    otp_verify : domain + "/paymentauth/otp_verify/",
    otp_resend : domain + "/paymentauth/otp_resend/",
    forgotpassword : domain + "/paymentauth/forgotpassword/",
    changepassword : domain + "/paymentauth/changepassword/",

    get_registeredcards : domain + "/payment/getregisteredcards/",
    get_sessiondetails : domain + "/paymentauth/sessiondetails/",
    customer_list : domain + "/paymentauth/user/list/",
    customer : domain + "/paymentauth/user/",
    password_view : domain + "/paymentauth/password_decrypt/",

    giftcardtopup : domain + "/payment/giftcardtopup/",
    giftcardnewreg : domain + "/payment/giftcardnewreg/",
    proceed_ccavenue : domain + "/payment/proceed_ccavenue/",
    proceed_newccavenue : domain + "/payment/proceed_newccavenue/",
    response_ccavenue : domain + "/payment/response_ccavenue/",
    proceed_dohabank : domain + "/payment/proceed_dohabank/",
    response_dohabank : domain + "/payment/response_dohabank/",
    proceed_smartpay : domain + "/payment/proceed_smartpay/",
    proceed_newsmartpay : domain + "/payment/proceed_newsmartpay/",
    response_smartpay : domain + "/payment/response_smartpay/",
    proceed_knet : domain + "/payment/proceed_knet/",
    proceed_newknet : domain + "/payment/proceed_newknet/",
    response_knet : domain + "/payment/response_knet/",
    proceed_payit : domain + "/payment/proceed_payit/",
    proceed_payitcard : domain + "/payment/proceed_payitcard/",
    payit_linkwallet : domain + "/payment/payit_linkwallet/",
    payit_capturepayment : domain + "/payment/payit_capturepayment/",

    activities_log_list : domain + "/paymentauth/activities_log/list/",
    activities_log : domain + "/paymentauth/activities_log/",
    sicollection_log_list : domain + "/payment/si_collection_log/list/",
    sicollection_log : domain + "/payment/si_collection_log/",
    equalsapi_log_list : domain + "/payment/equalsapi_log/list/",
    equalsapi_log : domain + "/payment/equalsapi_log/",


    getcustomerinfo : domain + "/payment/getcustomerinfo/",
    getpayhistory : domain + "/payment/getpayhistory/",

    terms_and_condition_by_country : domain + "/payment/terms_and_condition_by_country/",

    payment_log_list : domain + "/payment/payment_transaction_log/list/",
    payment_log : domain + "/payment/payment_transaction_log/",

    reconfirm_payment : domain + "/payment/reconfirm_payment/",


}
