import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardHeader,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import service from "../../../../services/service";
import DataTable from "react-data-table-component";

import Select from "react-select";
import Loader from "../../../../components/Loader";
import CustomModal from "../../../../components/CustomModal";

const Scheme = () => {
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");
  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  let navigate = useNavigate();
  let location = useLocation();
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;
  let breadcrumbData = [
    {
      title: "Dashboard",
      href: "/admin/dashboard",
    },
    {
      title: "Scheme",
      href: "#",
    },
  ];
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState("");

  const [countryList, setCountryList] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);

  const deleteRow = (row) => {
    console.log("delete", row.id);
  };

  const renderForm = (row) => {
    if (row)
      return navigate({
        pathname: "/admin/masters/schemeForm",

        state: "",
      });
    else
      return navigate({
        pathname: "/admin/masters/schemeForm",
        state: {
          // vendor_id : propsState.id,
        },
      });
  };

  const handleSort = async (column, sortDirection) => {
    console.log(column, sortDirection);
    if (sortDirection == "asc") {
      setSorting(column.sorting_name);
    } else {
      setSorting("-" + column.sorting_name);
    }
  };

  const schemeList = () => {
    let data = {};
    if (selectedCountry) {
      data.country_id = selectedCountry.value;
    }
    if (search.length > 0) {
      data.search = search;
    }
    setLoader(true);

    axios({
      url: service.scheme_list,
      method: "get",
      headers: { Authorization: tokenKey },
      params: { ...data, ordering: sorting, page: page, size: perPage },
    })
      .then((res) => {
        settableData(res.data.results);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
  };

  const handleCountryList = () => {
    setLoader(true);
    axios
      .get(service.country_details)
      .then((res) => {
        let countrylist = [];
        res.data.map((obj) => {
          countrylist.push({
            value: obj.id,
            label: obj.country_name,
          });
        });

        console.log(countrylist, res.data);
        setCountryList(countrylist);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "20px",
        justifyContent: "flex-start",
        // paddingLeft: '0px', // override the cell padding for head cells
        // paddingRight: '0px',
      },
    },
    rows: {
      style: {
        fontSize: "18px",
        minHeight: "52px",
      },
    },
    // headCells: {
    //   style: {
    //     textAlign: 'center', // Center-align column names
    //     fontSize: '50px',

    //   },
    // },
  };

  useEffect(() => {
    schemeList();
    if (countryList.length <= 0) {
      handleCountryList();
    }
  }, [search, page, perPage, selectedCountry,sorting]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPage(page);
    setPerPage(newPerPage);
  };

  const columns = [
    {
      id: 1,
      name: "Scheme ID",
      selector: (row) => row.scheme_id,
      sortable: true,
      reorder: true,
      sorting_name: "scheme_id",
    },
    {
      id: 2,
      name: "Scheme Name",
      selector: (row) => row.scheme_name,
      sortable: true,
      reorder: true,
      sorting_name: "scheme_name",
    },
    {
      id: 3,
      name: "Country",
      selector: (row) => row.country_id.country_name,
      sortable: true,
      // right: true,
      reorder: true,
      sorting_name: "country_id__country_name",
    },
    {
      name: "Actions",

      cell: (row) => (
        <>
          <span
            onClick={() => {
              console.log(row.id);
              return navigate("/admin/masters/schemeForm", {
                state: {
                  id: row.id,
                  editMode: true,
                },
              });
            }}
            className="btn btn-grad-primary"
          >
            View
          </span>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  return (
    // <Container>
    <Loader loading={loading} overlay={overlay}>

    <div>
      <div>
        <div className="pageNav">
          <div className="bold-text">
            {/* <Breadcrumbs data={breadcrumbData} /> */}
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
          <Card className="country__list">
            <>
            <Row className="d-flex justify-content-between">
                <Col md={4}>
                  <h3 className="pt-3 px-3 text-primary">Scheme</h3>
                </Col>
                <Col md={4}>
                  <div className="pt-3">

            <Breadcrumbs data={breadcrumbData} />
                  </div>

                </Col>
                <Col md={3} className="d-flex justify-content-end">
                  <Button
                    className="mt-3 ms-3 btn-grad-primary"
                    onClick={(e) => renderForm()}
                  >
                    Create
                  </Button>
                </Col>
                <Col md={1}></Col>
              </Row>
              <hr />
              <Row className="d-flex   formBtnsDiv mb-2 mt-3">
                <Col md={3}>
                  <div className="mx-3">
                    <Select
                      onChange={setSelectedCountry}
                      value={selectedCountry}
                      options={countryList}
                      placeholder="Select Country"
                      isSearchable={false}
                    />
                  </div>
                </Col>
                <Col md={1}></Col>
                
              </Row>
            </>
            <hr/>
            <CardBody>
              {/* <div className="pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div> */}
             
              
              <Row className="d-flex justify-content-between align-content-center formBtnsDiv mar-b-10 mt-3 mb-3">
                <Col md={4}>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      style={{ width: "80%" }}
                    />
                  </div>
                </Col>
                <Col md={4}>

                </Col>
                <Col md={2}>
                  <InputGroup className="mb-3 w-100">
                    <Form.Control
                      placeholder="Go to page"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      id="searchPage"
                    />
                    <Button
                      variant="outline-primary"
                      id="button-addon2"
                      onClick={() => {
                        setPage(document.getElementById("searchPage").value);
                      }}
                    >
                      go
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
              <DataTable
                  columns={columns}
                  data={tableData}
                  defaultSortFieldId={1}
                  pagination
                  paginationServer
                  customStyles={customStyles}
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  onSort={handleSort}
                    sortServer
                  />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      </div>
      </Loader>
    // </Container>
  );
};

export default Scheme;
