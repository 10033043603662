import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { useSelector, useDispatch } from "react-redux";
import "./common.css";
import Select from "react-select";
import empty from "is-empty";
import axios from "axios";
import service from "../services/service";
import Loader from "../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import CustomModal from "../components/CustomModal";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { clearAllCookies } from "../actions/authActions";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
const base = process.env.PUBLIC_URL;

const kalyan_bg = `${base}/images/kalyan_bg.png`;
const kalyan_logo = `${base}/images/kalyan_logo.png`;

function containsSpecialCharacters(str) {
  var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
  return regex.test(str);
}



const popover = (
  <Popover id="popover-basic">
    <Popover.Header className="bg-primary text-white" as="h3">Password criteria</Popover.Header>
    <Popover.Body>
    <div className="">
              <ul><li>Password should be minimum 8 characters</li><li>Password should contain atleast 1 numeric, 1 alphabet, 1 special character </li></ul>
            </div>
    </Popover.Body>
  </Popover>
);

const Signup = () => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState("+");

  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [view, setView] = useState(false);

  const authDetail = useSelector((state) => state.authDetail);
  const { details } = authDetail;

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  const [isActive, setIsActive] = useState(false);

  const handleFocus = () => {
    setIsActive(true);
  };

  let dispatch = useDispatch();


  const navigate = useNavigate();

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleInput = (keyName, value) => {
    console.log(keyName, value);

    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const findErrors = () => {
    const { email, mobile, password, confirmpassword } = form;
    const newErrors = {};

    if (empty(email)) newErrors.email = "This is a required field";
    if (empty(mobile)) newErrors.mobile = "This is a required field";
    if (empty(confirmpassword))
      newErrors.confirmpassword = "This is a required field";
    if (empty(password)) newErrors.password = "This is a required field";
    if (password !== confirmpassword)
      newErrors.validatepassword = "Password mismatched";
    console.log();
    return newErrors;
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  function handleSubmit(event) {
    event.preventDefault();
    // console.log(event)
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      if (newErrors.validatepassword) {
        setShow(true);
        setBody(newErrors.validatepassword);
      } else {
        setErrors(newErrors);
        console.log(newErrors);
        toast.error("Enter all required details to proceed", toastAttr);
      }
    } else {
      setLoader(true);
      let url = service.user_signup,
        data = JSON.parse(JSON.stringify(form));
      data.access_key = "kfhjdn7485kfg";
      data.country_id = selectedOption.value;
      data.mobile = selectedOption.mobile_prefix + form.mobile;
      console.log(data,"#####################################-----------");
      
      
      function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
          var cookies = document.cookie.split(/=|;/);
          cookieValue = decodeURIComponent(cookies[1]);
        }
        return cookieValue;
      }
      var csrftoken = getCookie('csrf_token');
      let header = {}
      if (csrftoken != null) {
        header['X-CSRFToken'] = csrftoken;
      }

      axios({
        url: url,
        method: "post",
        data: data,
        headers: { ...header },

      })
        .then((res) => {
          // if (editMode) setForm(res.data);
          console.log(res);
          if (res.status == 200) {
            setShow(true);
            setBody("signup successfully");
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }

          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          if (err?.response?.status === 500) {
            setShow(true)
            setBody(err.response.data)
          } else if (err?.response?.status === 400) {
            setShow(true)
            setBody(err.response.data.message)
          } else {
            setShow(true)
            setBody(err.response.data.message)
          }
        });
    }
  }
  
  function validateInput(input, pattern) {
    return pattern.test(input);
  }

  const emailValidate = (e) => {
    console.log(e.target.value);
    const isValidEmail = validateInput(
      e.target.value,
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    );
    if (!isValidEmail) {
      console.log(e.target.value, "ullara");

      setShow(true);
      setBody("Enter a valid Email ID");
      setForm({
        ...form,
        email: "",
      });
    } else {
      setForm({
        ...form,
        email: e.target.value,
      });
    }
  };

  const mobileValidate = (e) => {
    let mobile_value =
      selectedOption && selectedOption.mobile_prefix + e.target.value;
    if (mobile_value && mobile_value.length > selectedOption.mobile_digits) {
      setShow(true);
      setBody("Enter valid mobile number");
      setForm({
        ...form,
        mobile: "",
      });
    }
  };
  const passwordValidate = (e) => {
    setIsActive(false)
    let str = e.target.value;
      var spl = containsSpecialCharacters(str);
      if ((str.length < 8) || (spl === false) || (str.length > 50) || (str.search(/\d/) == -1) || (str.search(/[a-z]/) == -1) || (str.search(/[A-Z]/) == -1)){
        toast.error("Password does not match the given creteria", toastAttr);
        return;
        
      } 
  };

  useEffect(() => {
    let tokenKey = sessionStorage.getItem("tokenkey") ? true : false;

    if (!tokenKey) {
      axios
        .get(service.country_details)
        .then((res) => {
          let countrylist = [];
          res.data.map((obj) => {
            countrylist.push({
              value: obj.id,
              label: (
                <div>
                  <img width="30px" src={base + obj.flag_url} /> &nbsp;
                  {obj.country_name}
                </div>
              ),
              mobile_prefix: obj.mobile_prefix,
              mobile_digits: obj.mobile_digits,
            });
          });

          console.log(countrylist, res.data);
          setCountryList(countrylist);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          if (err?.response?.status === 500) {
            setShow(true)
            setBody(err.response.data)
          } else if (err?.response?.status === 400) {
            setShow(true)
            setBody(err.response.data.message)
          } else {
            setShow(true)
            setBody(err.response.data.message)
          }
        });
    }

    if (sessionStorage.getItem("tokenkey") && details) {
      if (details.user_type === "internal") {
        navigate("/admin/dashboard");
      } else if (details.user_type === "customer") {
        navigate("/giftcardregistered");
      }
    }
    console.log(selectedOption);
  }, [details, countryCode, selectedOption]);

  return (
    <Loader loading={loading} overlay={overlay}>
      <div
        className="login__signup__background"
        style={{ backgroundImage: `url(${kalyan_bg})` }}
      >
         <div className="d-flex flex-row justify-content-end">
              <button
                className="btn btn-link text-muted"
                
              >
                <Link style={{color:"whitesmoke"}} to="/termsandcondition">Terms & Conditions</Link>
              </button>
            </div>
        <Card className="login__signup__card">
          <div className="d-flex justify-content-center my-3">
            <img src={kalyan_logo} className="kalyan__logo" alt="kalyan_logo" />
          </div>
          <Card.Body>
            <Form.Control
              className="form-control mt-4 fs-5"
              id="emailid"
              type="email"
              value={form.email}
              placeholder="Enter Email ID"
              onChange={(e) => handleInput("email", e.target.value)}
              onBlur={(e) => emailValidate(e)}
              isInvalid={!!errors.email}
              onKeyUp={(event) => {
                if (event.key == "Enter") {
                  document.getElementById("selectCountry").focus();
                }
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>

            <Select
              id="selectCountry"
              onChange={setSelectedOption}
              value={selectedOption}
              options={countryList}
              placeholder="Select Country"
              isSearchable={false}
              className="select-type mt-4 fs-5"
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              onKeyUp={(event) => {
                if (event.key == "Enter") {
                  document.getElementById("mobileNumber").focus();
                }
              }}
            />
            <InputGroup>
              <InputGroup.Text className=" mt-4 fs-5" id="basic-addon1">
                + {selectedOption && selectedOption.mobile_prefix}{" "}
              </InputGroup.Text>
              <Form.Control
                id="mobileNumber"
                className="form-control mt-4 fs-5"
                type="text"
                maxLength={selectedOption?.mobile_digits - 3}
                value={form.mobile}
                placeholder="Enter Mobile number"
                onChange={(e) => handleInput("mobile", e.target.value)}
                isInvalid={!!errors.mobile}
                onBlur={(e) => mobileValidate(e)}
                onKeyUp={(event) => {
                  if (event.key == "Enter") {
                    document.getElementById("password").focus();
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobile}
              </Form.Control.Feedback>
            </InputGroup>

            {/* {isActive ? <div className="bg-warning text-white p-3 mt-4">
              <ul><li>Password should be minimum 8 characters</li><li>Password should contain atleast 1 numeric, 1 alphabet, 1 special character </li></ul>
            </div> : ''} */}



            <InputGroup>
            <OverlayTrigger trigger="click" show={isActive} placement="top" overlay={popover}>
              <Form.Control
                id="password"
                className="form-control mt-4 fs-5 "
                
                value={form.password}
                type={view ? "text" : "password"}
                placeholder="Enter Password"
                onFocus={handleFocus}
                onBlur={(e) => passwordValidate(e)}
                onChange={(e) => handleInput("password", e.target.value)}
                isInvalid={!!errors.password}
                onKeyUp={(event) => {
                  if (event.key == "Enter") {
                    document.getElementById("confirmpassword").focus();
                  }
                }}
                />
                </OverlayTrigger>
              <InputGroup.Text
                className=" mt-4 fs-5 "
                id="basic-addon1"
                onClick={() => setView(!view)}
              >
                {view ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </InputGroup.Text>
            </InputGroup>


            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
            <Form.Control
              className="form-control mt-4 fs-5"
              type="password"
              id="confirmpassword"
              value={form.confirmpassword}
              placeholder="Confirm Password"
              onFocus={handleFocus}

              onBlur={(e) => passwordValidate(e)}
              onChange={(e) => handleInput("confirmpassword", e.target.value)}
              isInvalid={!!errors.confirmpassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmpassword}
            </Form.Control.Feedback>
            <button
              className="btn btn-primary form-control mt-4 fs-5 text-white"
              onClick={handleSubmit}
            >
              Sign Up
            </button>
          </Card.Body>
          <p className="text-center">
            Are you have an account?
            <Link className="fw-bold btn btn-link text-primary" to="/">
              Login Here
            </Link>
          </p>
        </Card>
        <CustomModal show={show} body={Body} handleClose={handleClose} />

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Loader>
  );
};

export default Signup;
