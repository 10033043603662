import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import { Navbar, Container, NavDropdown, Image, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import service from "../services/service";
import ProfileView from "./profile";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faArrowRightFromBracket,
  faCircleUser,
  faFileCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import "./Sidebar.css";
import { clearAllCookies } from "../actions/authActions";

const Nav = styled.div`
  background: linear-gradient(to right, #d71a21 0%, #b31217 51%, #e52d27 100%);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 28px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
`;

const NavRight = styled.div`
  margin-right: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 18px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;

  @media only screen and (max-width: 567px) {
    display: none;
  }
`;

const MobileVisible = styled.span`
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const SidebarNav = styled.nav`
  background: #ffffff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  box-shadow: 2px 0px 20px 2px rgba(0, 0, 0, 0.1);
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarLink = styled(Link)`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #d71a21;
    color: white;
    border-left: 4px solid grey;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const NavRightContent = styled.div`
  transition: 0.7s;
  color: white;
  border: 1px solid white;
  padding: 5px;
  border-radius: 6px;

  &:hover {
    color: #d71a21;
    background: white;
    border: 1px solid #d71a21;
    cursor: pointer;
    border-radius: 6px;
  }
`;

const kalyan_icon = `${process.env.PUBLIC_URL}/images/kalyan_icon.png`;
const kalyan_logo = `${process.env.PUBLIC_URL}/images/kalyan_logo.png`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const authDetail = useSelector((state) => state.authDetail);
  const { error, details } = authDetail;
  console.log(details);
  const SidebarData = details?.common_menu;
  console.log(SidebarData);
  const showSidebar = () => setSidebar(!sidebar);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShowModal(e);
  };

  const handleSidebarClose = (e) => {
    console.log(e, "from child");
    setSidebar(e);
  };

  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const logoutHandler = () => {
    (details?.user_type == "internal") ?(
      axios({
        url: service.user_logout,
        method: "post",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          console.log(res);
          sessionStorage.clear();
          if (res.status == 200) {
            window.location.href = "/admin/login";
          }
        })
        .catch((err) => {
          // if (err.response.status === 401) window.location.href = "/";
          console.log(err);
        })
    ):(axios({
      url: service.user_logout,
      method: "post",
      headers: { Authorization: tokenKey },
    })
      .then((res) => {
        console.log(res);
        sessionStorage.clear();
        if (res.status == 200) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) window.location.href = "/";
        console.log(err);
      }))
    
  };

  useEffect(() => {}, [details]);

  return (
    <>
      {error?.message}
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavIcon to="#">
            {details?.user_type == "internal" ? (
              <FaIcons.FaBars onClick={showSidebar} />
            ) : (
              <MobileVisible>
                <FaIcons.FaBars onClick={showSidebar} />
              </MobileVisible>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link to="/" style={{ textDecoration: "none" }}>
              <Navbar.Brand>
                <Image src={kalyan_icon} height="30px" width="27px" />
                &nbsp;{" "}
                <strong style={{ color: "white", fontSize: "20px" }}>
                  Kalyan Jewellers
                </strong>
              </Navbar.Brand>
            </Link>
          </NavIcon>

          <NavRight>
            <Link
              style={{ textDecoration: "none" }}
              className="p-3 text-white focus-link"
              to="/giftcard"
            >
              Register Gift Card
            </Link>

            <Link
              style={{ textDecoration: "none" }}
              to="https://www.kalyanjewellers.net/contact-us.php"
              className="p-3 text-white focus-link"
              target="_blank"
            >
              Support
            </Link>

            {/* <Link style={{ textDecoration: "none" }} to="/termsandcondition">
              <span className="p-3 text-white">Terms & Conditions</span>
            </Link> */}
            <span className="p-3 text-white">
              {details?.country_id && details.country_id?.country_name}
            </span>
            <NavRightContent onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faCircleUser} className="px-1" />

              <span className="p-1">{details?.username}</span>
              <span className="p-1"></span>
            </NavRightContent>
          </NavRight>
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <div>
              {/* <Navbar.Brand>
            <Image src={kalyan_icon} height="35px" width="30px" />
            &nbsp; <strong style={{ color: "white" }}>Kalyan Jewellers</strong>
          </Navbar.Brand> */}
              <NavIcon style={{ marginTop: "0rem" }} to="#">
                <Image src={kalyan_logo} height="60px" width="100px" />
                <AiIcons.AiOutlineClose color="black" onClick={showSidebar} />
              </NavIcon>
            </div>

            {details && details.user_type === "customer" && (
              <SidebarLink to="https://www.kalyanjewellers.net/contact-us.php">
                <div>
                  <FontAwesomeIcon icon={faGear} />
                  <SidebarLabel>Support</SidebarLabel>
                </div>
              </SidebarLink>
            )}

            {SidebarData &&
              SidebarData.map((item, index) => {
                return (
                  <SubMenu
                    item={item}
                    key={index}
                    handleSidebarClose={handleSidebarClose}
                  />
                );
              })}

            {details &&
              (details.user_type === "customer" ||
                details.user_type === "internal") && (
                <SidebarLink
                  onClick={() => {
                    setShowModal(true);
                    showSidebar();
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={faCircleUser} />

                    <SidebarLabel>Profile</SidebarLabel>
                  </div>
                </SidebarLink>
              )}

            <SidebarLink
              onClick={() => {
                logoutHandler();
                showSidebar();
              }}
            >
              <div>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                <SidebarLabel>Logout</SidebarLabel>
              </div>
            </SidebarLink>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
      <ProfileView
        show={showModal}
        handleClose={handleClose}
        logoutHandler={logoutHandler}
      />

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Sidebar;
