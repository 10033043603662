import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { Card, Form, Col, Modal, Row } from "react-bootstrap";

import CloseButton from "react-bootstrap/CloseButton";
import service from "../services/service";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { InputGroup } from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { authDetail } from "../actions/authActions";
import { ToastContainer, toast } from "react-toastify";
import CustomModal from "./CustomModal";
import { useNavigate } from "react-router-dom";


const kalyan_logo = `${process.env.PUBLIC_URL}/images/kalyan_logo.png`;

const useMatchMedia = (mediaQuery, initialValue) => {
  const [isMatching, setIsMatching] = useState(initialValue);
  useEffect(() => {
    const watcher = window.matchMedia(mediaQuery);
    setIsMatching(watcher.matches);
    const listener = (matches) => {
      setIsMatching(matches.matches);
    };
    if (watcher.addEventListener) {
      watcher.addEventListener("change", listener);
    } else {
      watcher.addListener(listener);
    }
    return () => {
      if (watcher.removeEventListener) {
        return watcher.removeEventListener("change", listener);
      } else {
        return watcher.removeListener(listener);
      }
    };
  }, [mediaQuery]);
  return isMatching;
};

function containsSpecialCharacters(str) {
  var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
  return regex.test(str);
}

const ProfileView = (props) => {
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  const navigate = useNavigate()

  const authDetail = useSelector((state) => state.authDetail);
  const { loading: detailLoading, error, details } = authDetail;

  const isDesktopResolution = useMatchMedia("(max-width:995px)", false);

  // const display = {
  //   display: "none",
  // };
  const [form, setForm] = useState({});
  const [view, setView] = useState(false);

  const [personalDetail, setPersonalDetail] = useState({});
  const [currentAddress, setCurrentAddress] = useState({});
  const [permanentAddress, setPermanentAddress] = useState({});
  const [dob, setDOB] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [email, setEmail] = useState("");



  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  let toastAttr = {
    theme: "colored",
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header className="bg-primary text-white" as="h3">Password criteria</Popover.Header>
      <Popover.Body>
      <div className="">
                <ul><li>Password should be minimum 8 characters</li><li>Password should contain atleast 1 numeric, 1 alphabet, 1 special character </li></ul>
              </div>
      </Popover.Body>
    </Popover>
  );

  const passwordValidate = (e) => {
    let str = newPassword1;

    setIsActive(false);
    var spl = containsSpecialCharacters(str);
    if (
      str.length < 8 ||
      spl === false ||
      str.length > 50 ||
      str.search(/\d/) == -1 ||
      str.search(/[a-z]/) == -1 ||
      str.search(/[A-Z]/) == -1
    ) {
      setShow(true);
      setBody("Password does not match the given creteria");
      return;
    }
  };

  const ChangePassword = () => {

    if(oldPassword == newPassword1){
      setShow(true);
      setBody("Old password and New password should not be same");
      setNewPassword1("")
    }
    else if (newPassword1 != newPassword2) {
      setShow(true);
      setBody("New password and Confirm password Should Be Same");
    } else {
      axios({
        url: service.changepassword,
        method: "post",
        data: {
          old_password: oldPassword,
          new_password1: newPassword1,
          new_password2: newPassword2,
        },
        headers: { Authorization: tokenKey },


      })
        .then((res) => {
          // sessionStorage.setItem("tokenkey", res.data.token);
          // dispatch(authDetails());
          
          setShowPasswordModal(false);
          toast.success("Password Changed successfully", toastAttr);
          setTimeout(()=>{sessionStorage.clear()
          navigate("/")},1000);
          console.log(res);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            setShow(true);
            setBody(err.response.data.message);
          } else if (err.response.status == 500) {
            setShow(true);
            setBody("Server Error!");
          } else if (err.response.status == 401) {
            setShow(true);
            setBody(err.response.data.detail);
          }
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (props.show) {
      axios({
        url: service.getcustomerinfo,
        method: "post",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setPersonalDetail(res.data.PersonalDetails);
          setCurrentAddress(res.data.CurrentAddress);
          setPermanentAddress(res.data.permanentAddress);
          setDOB(res.data.DOB);
        })
        .catch((err) => {});
    }
  }, [props.show]);

  return (
    <>
      <Modal
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        size="xl"
        centered
      >
        <Modal.Header className="modalotpheader">
          <div className="d-flex justify-content-between">
            <span className="fw-bold fs-5">Customer Profile</span>
            
          </div>

          <button
            className="btn btn-link"
            onClick={(e) => props.handleClose(false)}
          >
            <CloseButton variant="white" />
          </button>
        </Modal.Header>
        <Modal.Body className=" p-0 mx-2 px-1 ">
          <Row
            className="pe-5"
            style={{ height: "550px", overflow: "scroll", overflowX: "hidden" }}
          >
            {/* <div className="bg-secondary position-relative col-md-3" style={{display:isDesktopResolution ? 'none' : 'flex'}}>
              <div className="position-absolute top-50 start-50 translate-middle">
                <div className="d-flex flex-column">
                  <img
                    src={kalyan_logo}
                    className="kalyan__logo ms-2"
                    alt="kalyan_logo"
                  />
                  <div className="d-flex flex-column justify-content-center">
                    <label className="d-flex justify-content-center text-white mt-4 fs-3">
                      Customer
                    </label>
                    <label className="d-flex justify-content-center text-white fs-4">
                      Profile
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
            <Card className=" ms-4 me-5 ms-3 my-4">
              <Col>
                <Row className="text-danger fw-bold fs-5 ms-1 mt-1">
                  PERSONAL INFORMATION
                </Row>
                <hr className="mt-1" />

                <Row className="mt-3 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        First Name
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {personalDetail.FirstName}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Last Name
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {personalDetail.LastName}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Mobile
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {personalDetail.MobileNumber}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Email
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {personalDetail.EmailAddress}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        DOB
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {dob}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="text-danger fw-bold fs-5 ms-1 mt-3">
                  CURRENT ADDRESS
                </Row>
                <hr className="mt-1" />
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Street 1
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress.Street1}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Street 2
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress.Street2}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        City
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress.City}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        State
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress.State}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        PO Box
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress.PostBox}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Country
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {currentAddress.Country}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="text-danger fw-bold fs-5 ms-1 mt-3">
                  PERMANENT ADDRESS
                </Row>
                <hr className="mt-1" />
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Street 1
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress.Street1}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Street 2
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress.Street2}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        City
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress.City}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        State
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress.State}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-1 ms-1">
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        PO Box
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress.PostBox}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col style={{ width: "40%" }} className="fw-bold">
                        Country
                      </Col>
                      <Col style={{ width: "60%", color: "#0d6efd" }}>
                        {permanentAddress.Country}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="text-danger fw-bold fs-5 ms-1 mt-3 d-flex justify-content-end p-2">
                  <span className="btn btn-grad-primary w-25 mx-2" onClick={() => {props.handleClose(false);setShowPasswordModal(true)}} >Change Password</span>
                  <span className="btn btn-grad-primary w-25" onClick={() => props.logoutHandler()} >Logout</span>
                </Row>
              </Col>
            </Card>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
          show={showPasswordModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="modalotpheader">
            <div className="d-flex justify-content-between">
              <span className="fw-bold fs-5">Change Password</span>
            </div>
            <button
              className="btn btn-link"
              onClick={() => setShowPasswordModal(false)}
            >
              <CloseButton variant="white" />
            </button>
          </Modal.Header>
          {/* <Modal.Header
            closeButton
            onClick={() => setShowPasswordModal(false)}
          ></Modal.Header> */}
          <Modal.Body>
          <Form.Control
              className="form-control mt-4 fs-5"
              type="password"
              placeholder="Old password"
              onChange={(e) => setOldPassword(e.target.value)}
            />
          <OverlayTrigger trigger="click" show={isActive} placement="top" overlay={popover}>
            <InputGroup className="mb-3">
              <Form.Control
                className="form-control mt-4 fs-5"
                value={form.password}
                type={view ? "text" : "password"}
                placeholder="Enter Password"
                onFocus={handleFocus}
                onBlur={(e) => passwordValidate(e)}
                onChange={(e) => setNewPassword1(e.target.value)}
              />
              <InputGroup.Text
                className=" mt-4 fs-5"
                id="basic-addon1"
                onClick={() => setView(!view)}
              >
                {view ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </InputGroup.Text>
            </InputGroup>
          </OverlayTrigger>
            {/* <Form.Control
              className="form-control mt-5 fs-5"
              type="password"
              placeholder="Enter New password"
              onChange={(e) => setNewPassword1(e.target.value)}
              onBlur={(e) => passwordValidate(e)}
            /> */}

            

            <Form.Control
              className="form-control mt-4 fs-5"
              type="password"
              placeholder="Confirm New password"
              onChange={(e) => setNewPassword2(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setShowPasswordModal(false)}
            >
              Close
            </button>
            <button
              className="btn btn-success"
              onClick={() => ChangePassword()}
              disabled={!newPassword1 || !newPassword2}
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
        <CustomModal show={show} body={Body} handleClose={handleClose} />

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </>
  );
};

export default ProfileView;
