import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

import "./ResponsePage.css";

const ResponsePayit = (props) => {
  
  const [paystatus, setPaystatus] = useState("");
  const [cardnumber, setCardnumber] = useState("");
  const [referencenumber, setReferencenumber] = useState("");
  const location = useLocation();
  


  // Load Default data by calling GiftCardTopup
  useEffect(() => {

    

    const data = location.state;

    // // Read Params from URL - Start
    // const searchParams = new URLSearchParams(document.location.search);
    // const params = {};
    // for (const key of searchParams.keys()) {
    //   params[key] = searchParams.get(key);
    // }
    // console.log(params, "params");
    // // Read Params from URL - End

    // let card_no = params["card_no"] ? params["card_no"] : "";
    // let referencenumber = params["ref_no"] ? params["ref_no"] : "";
    // let pay_status = params["status"] ? params["status"] : "";
    let card_no = data.card_no
    let referencenumber =data.ref_no
    let pay_status = data.status
    console.log(card_no, "card");
    console.log(referencenumber, "referencenumber");
    console.log(pay_status, "pay_status");
    setCardnumber(card_no);
    setReferencenumber(referencenumber);
    setPaystatus(pay_status);
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="ms-3">
                <Row>
                  <Col md={12}>
                    {}

                    <div>
                      <div class="check" id="success-mark">
                        <i
                          class="fa fa-check text-success"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="check" id="fail-mark">
                        <i
                          class="fa fa-times text-danger"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div className="content">
                      {paystatus == "Payment Success" ? (
                        <div class="svg-box">
                          <svg class="circular green-stroke">
                            <circle
                              class="path"
                              cx="75"
                              cy="75"
                              r="50"
                              fill="none"
                              stroke-width="5"
                              stroke-miterlimit="10"
                            />
                          </svg>
                          <svg class="checkmark green-stroke">
                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                              <path
                                class="checkmark__check"
                                fill="none"
                                d="M616.306,283.025L634.087,300.805L673.361,261.53"
                              />
                            </g>
                          </svg>
                        </div>
                      ) : (
                        <div class="svg-box">
                          <svg class="circular red-stroke">
                            <circle
                              class="path"
                              cx="75"
                              cy="75"
                              r="50"
                              fill="none"
                              stroke-width="5"
                              stroke-miterlimit="10"
                            />
                          </svg>
                          <svg class="cross red-stroke">
                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                              <path
                                class="first-line"
                                d="M634.087,300.805L673.361,261.53"
                                fill="none"
                              />
                            </g>
                            <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                              <path
                                class="second-line"
                                d="M634.087,300.805L673.361,261.53"
                              />
                            </g>
                          </svg>
                        </div>
                      )}

                      <h1>{paystatus}</h1>
                      <p>
                        Please find below your gift card number and payment
                        reference number.{" "}
                      </p>
                      <p>
                        <b>Giftcard No. : </b>
                        <span>{cardnumber}</span>
                      </p>
                      <p>
                        <b>Reference Number : </b>
                        <span>{referencenumber}</span>
                      </p>
                      {/* <p *ngIf="si_status=='yes' && paymentstatus=='success'"><b>Successfully Registered for Monthly Auto debit from credit card</b></p> */}
                      <a href="/giftcardregistered" className="btn-grad-primary">
                        Go to Home
                      </a>
                    </div>
                  </Col>
                </Row>

                <hr />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResponsePayit;
