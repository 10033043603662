import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Button,
  DropdownButton,
  Form,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import service from "../../../../services/service";
import Loader from "../../../../components/Loader";
import empty from "is-empty";
import Breadcrumbs from "../../../../components/Breadcrumbs";

import "../../.././common.css";
import CustomModal from "../../../../components/CustomModal";


const SchemeForm = () => {
  let navigate = useNavigate();
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  let location = useLocation();

  let propsState = location.state;
  const [form, setForm] = useState({});
  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  const [editable, setEditable] = useState(propsState?.editMode ? false : true)

  const toggle=()=>{
    setEditable(!editable)
  }

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Dashboard",
      href: "/admin/dashboard",
    },
    {
      title: "Scheme",
      href: "/admin/masters/scheme",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Scheme Details",
      id: "details",
      show: true,
    },
  ]);

  const [country, setCountry] = useState([]);

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    return navigate("/admin/masters/scheme");
  };

  const findErrors = () => {
    const { scheme_id, scheme_name, country_id, min_amount, max_amount } = form;
    const newErrors = {};

    if (empty(scheme_id)) newErrors.scheme_id = "This is a required field";
    if (empty(scheme_name)) newErrors.scheme_name = "This is a required field";
    if (empty(country_id)) newErrors.country_id = "This is a required field";
    if (empty(min_amount)) newErrors.min_amount = "This is a required field";
    if (empty(max_amount)) newErrors.max_amount = "This is a required field";
    return newErrors;
  };

  const saveForm = () => {

    let id = editMode ? propsState.id : null,
      method = editMode ? "put" : "post";
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
          ? service.scheme_create + form.id + "/update/"
          : service.scheme_create,
        data = JSON.parse(JSON.stringify(form));
      if(method=='put'){
        data.country_id = data.country_id.id
      }
      axios({
        url: url,
        method: method,
        headers: { Authorization: tokenKey },
        data: data,
      })
      .then((res) => {
        if (editMode) setForm(res.data);
        console.log(res);
        if (method == "post") {
          toast.success("Created successfully", toastAttr);
          setTimeout(navigate(-1), 1000);
        }
        if (method === "put") {
          toast.success("Updated successfully", toastAttr);
          setTimeout(navigate(-1), 1000);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
    }
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  useEffect(() => {
    setLoader(true);
    if (propsState && propsState.editMode) {
      axios({
        url: service.scheme_create+ propsState.id +'/',
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setForm(res.data)
        })
        .catch((err) => {
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          if (err?.response?.status === 500) {
            setShow(true)
            setBody(err.response.data)
          } else if (err?.response?.status === 400) {
            setShow(true)
            setBody(err.response.data.message)
          } else {
            setShow(true)
            setBody(err.response.data.message)
          }
        });
    
      setLoader(false);
    }
    if (country.length == 0) {
      axios({
        url: service.country_list,
        method: "get",
        headers: { Authorization: tokenKey },
      })
        .then((res) => {
          setCountry(res.data.results);
        })
        .catch((err) => {
          setLoader(false);
  
          if (err?.response?.status === 401) {
            window.location.href = "/";
          }
          if (err?.response?.status === 500) {
            setShow(true)
            setBody(err.response.data)
          } else if (err?.response?.status === 400) {
            setShow(true)
            setBody(err.response.data.message)
          } else {
            setShow(true)
            setBody(err.response.data.message)
          }
        });
    }
    setLoader(false);

    // });
  }, []);
  return (
    <Loader loading={loading} overlay={overlay}>

    <Container>
      <Row>
        <Col md={12}>
          <Card className="country__list">
            <CardBody className="ms-3">
            <Row className="d-flex justify-content-between">
                <Col md={4}>
                  <h3 className="pt-3 px-3 text-primary">Scheme Details</h3>
                </Col>
                
                <Col md={4}>
                  <div className="pt-3">

            <Breadcrumbs data={breadcrumbData} />
                  </div>

                </Col>
                <Col md={4}>
                  
                </Col>
              </Row>
              <hr />

              <Row className="mb-3 d-flex justify-content-between">
                <Col md={10}>
                  <Button
                    variant="primary"
                    disabled={!editable}
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    {editMode? "Update": "Save"}
                  </Button>
                  <Button
                    className="ms-2"
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={2} hidden={!propsState?.editMode}>
                  <Button
                      className="ms-2 btn-grad-primary"
                      onClick={(e) => {
                        toggle();
                      }}
                    >
                      Edit
                    </Button>
                  </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {/* <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav> */}
                  <div>
                    <Form>
                      
                        {tab.details ? (
                          <>
                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Country
                                    <span className="text-danger">*</span></Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Select
                                      name="country_id"
                                      className="input-border"
                                      placeholder="select country"
                                      value={form.country_id?.id}
                                      onChange={(e) =>
                                        handleInput(
                                          "country_id",
                                          e.target.value
                                        )
                                      }
                                      isInvalid={!!errors.country_id}
                                      disabled={!editable}
                                    >
                                      <option value="">Select Country</option>
                                      {country.map((cou) => (
                                        <option
                                          key={cou.id}
                                          value={cou.id}
                                          className="dropdown"
                                        >
                                          {cou.country_name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.country_id}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Scheme ID
                                    <span className="text-danger">*</span></Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      type="number"
                                      name="scheme_id"
                                      className="input-border"
                                      placeholder="Enter Scheme ID"
                                      value={form.scheme_id}
                                      onChange={(e) =>
                                        handleInput("scheme_id", e.target.value)
                                      }
                                      isInvalid={!!errors.scheme_id}
                                      readOnly={!editable}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.scheme_id}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="py-3">
                              <Col md={12} className="d-grid gap-3">
                                <Row>
                                  <Col md={2}>
                                    <Form.Label className="mt-2">
                                      Scheme Name
                                    <span className="text-danger">*</span></Form.Label>
                                  </Col>
                                  <Col md={9}>
                                    <Form.Control
                                      type="text"
                                      name="scheme_name"
                                      className="input-border"
                                      placeholder="Enter Scheme Name"
                                      value={form.scheme_name}
                                      onChange={(e) =>
                                        handleInput(
                                          "scheme_name",
                                          e.target.value
                                        )
                                      }
                                      isInvalid={!!errors.scheme_name}
                                      readOnly={!editable}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.scheme_name}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="py-3">
                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Min. Amount
                                    <span className="text-danger">*</span></Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      type="number"
                                      name="min_amount"
                                      className="input-border"
                                      placeholder="Enter Min. Amount"
                                      value={form.min_amount}
                                      onChange={(e) =>
                                        handleInput(
                                          "min_amount",
                                          e.target.value
                                        )
                                      }
                                      isInvalid={!!errors.min_amount}
                                      readOnly={!editable}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.min_amount}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6} className="d-grid gap-3">
                                <Row>
                                  <Col md={4}>
                                    <Form.Label className="mt-2">
                                      Max. Amount
                                    <span className="text-danger">*</span></Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      type="number"
                                      name="max_amount"
                                      className="input-border"
                                      placeholder="Enter Max. Amount"
                                      value={form.max_amount}
                                      onChange={(e) =>
                                        handleInput(
                                          "max_amount",
                                          e.target.value
                                        )
                                      }
                                      isInvalid={!!errors.max_amount}
                                      readOnly={!editable}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.max_amount}
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}
                      <CustomModal
                            show={show}
                            body={Body}
                            handleClose={handleClose}
                          />
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    </Loader>
  );
};

export default SchemeForm;
