import axios from 'axios';
import React,{useState, useEffect} from 'react'
// import { Card, CardBody, Container } from 'reactstrap'
import { Card, Container, Form, Modal, Button, ModalHeader, Col } from "react-bootstrap";
import service from '../../services/service';
import Loader from '../../components/Loader';
import CustomModal from '../../components/CustomModal';
import { ToastContainer, toast } from "react-toastify";
import CloseButton from "react-bootstrap/CloseButton";
import { useNavigate,useLocation } from "react-router-dom";


const ProceedPayit = () => {
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  let location = useLocation();

  const [loading, setloading] = useState(false);
  const [overlay, setoverlay] = useState(false);

  const [loading1, setloading1] = useState(false);
  const [overlay1, setoverlay1] = useState(false);

  const [mobile,setMobile] = useState("")
  const [giftCardId,setGiftCardId] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [otp_, setOtp_] = useState("");

  const [otp_type, setOtpType] = useState("");
  const [showotp, setShowotp] = useState(true);


  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const navigate = useNavigate();


  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };


  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);

  };

  const setLoader1 = (val) => {
    setloading1(val);
    setoverlay1(val);
  };

  let toastAttr = {
    theme: "colored",
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const toggleModal = (close) => {
    // alert(close)
    
    setShowModal(!showModal);
    setOtp_("");
  };


  const verifyOTP = (e) => {
    console.log(
      document.getElementById("otp").value,
      "document.getElementById('otp')"
    );
    let data = {
      mobile: mobile,
      otp_type: otp_type,
      otp: document.getElementById("otp").value,
    };
    setLoader1(true)
    axios({
      url: service.otp_verify,
      method: "post",
      data: data,
      headers: { Authorization: tokenKey },


    })
      .then((res) => {

        if (otp_type == "login_verify") {
          if (res.data.user_type === "internal") {
            toast.success("Verified successfully", toastAttr);
            // setTimeout(() => {
            //   navigate("/admin/dashboard");
            // }, 1000);
          }
        } 
        setMobile('')
        setShowModal(false);
        setLoader1(false);
      })
      .catch((err) => {
        if (err.response.status == 400) {
          setShow(true);
          setBody(err.response.data.message);
        } else if (err.response.status == 500) {
          setShow(true);
          setBody("Server Error!");
        } else if (err.response.status == 401) {
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader1(false);
      });
  };

  function sendOTP(e){

    let url = service.proceed_payit;

      console.log("-----------");

    
setLoader(true)
      axios({
        url: url,
        method: "post",
        data: {"mobileno":mobile,"giftcard_topup_id":giftCardId},
      headers: { Authorization: tokenKey },

      }).then((res)=>{
        // toggleModal();
            setLoader(false);
            // setShowotp(true);
            setShowModal(true);
            // OTPInput()
            // setOtpType("login_verify");
            return;
      }).catch((err) => {
        if (err.response.status == 400) {
          setShow(true);
          setBody(err.response.data.message);
        } else if (err.response.status == 500) {
          setShow(true);
          console.log(err.response)
          setBody(err.response?.data);
        } else if (err.response.status == 401) {
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader(false)
      });

  }

  const EnterOTP = (props) => {

    // if (showotp == true) {
      return (
        <>
          <div className="d-flex justify-content-center mt-5 fw-bold">
            Enter OTP
          </div>
          <div className="d-flex justify-content-center">
            
            <div className='col-md-3'>
              <input
                className="mt-3 form-control text-center"
                id="otp"
                maxLength={6}
                type="text"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button className="btn btn-link" onClick={sendOTP}>
              Resend OTP
            </button>
          </div>
        </>
      );
  //   } else {
  //     return (
  //       <div className="d-flex justify-content-center mt-3 fw-bold">
  //         <button
  //           className="btn btn-success"
  //           onClick={sendOTP}
  //           disabled={!mobile}
  //         >
  //           Send OTP
  //         </button>
  //       </div>
  //     );
  //   }
  };

  useEffect(() => {
    console.log(location);
    let giftcard_topup_id = location.state ? location.state.returndata : null;
    console.log(giftcard_topup_id);
    setGiftCardId(giftcard_topup_id)
  }, [])
  

  
  return (
    <Loader loading={loading} overlay={overlay}>

    <Container>
    <Card className="payit__card">
          <Card.Body>
            <Form.Control
              type="number"
              id="mobile"
              className="form-control mt-5 fs-5"
              placeholder="Mobile number"
              value={mobile}
              onChange={e=>setMobile(e.target.value)}
            />

            <button
              id="mobile"
              className="btn btn-primary form-control my-5 fs-5 text-white"
              onClick={sendOTP}
              disabled={!mobile}
            >
              Send OTP
            </button>
          </Card.Body>
        </Card>
        </Container>

        <Modal
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="modalotpheader">
              <div className="d-flex justify-content-between">
                <span className="fw-bold fs-5">Verify OTP</span>
              </div>
              <button
                className="btn btn-link"
                onClick={() => toggleModal("close")}
              >
                <CloseButton variant="white" />
              </button>
            </Modal.Header>
            <Modal.Body>
            <Loader loading={loading1} overlay={overlay1}>
                <EnterOTP />
                </Loader>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => toggleModal("close")}
              >
                Close
              </button>
              <button className="btn btn-primary" onClick={verifyOTP}>
                Verify
              </button>
            </Modal.Footer>
          </Modal>
          
        <CustomModal show={show} body={Body} handleClose={handleClose} />

    <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </Loader>
  )
}

export default ProceedPayit
