import axios from 'axios';
import React, { useState, useEffect } from 'react'
// import { Card, CardBody, Container } from 'reactstrap'
import { Card, Container, Form, Modal, Button, ModalHeader, Col } from "react-bootstrap";
import service from '../../services/service';
import Loader from '../../components/Loader';
import CustomModal from '../../components/CustomModal';
import { ToastContainer, toast } from "react-toastify";
import CloseButton from "react-bootstrap/CloseButton";
import { useNavigate, useLocation } from "react-router-dom";
import ResponsePayit from './ResponsePayitcard';


const ProceedPayitCard = () => {
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  let location = useLocation();

  const [loading, setloading] = useState(false);
  const [overlay, setoverlay] = useState(false);

  const [loading1, setloading1] = useState(false);
  const [overlay1, setoverlay1] = useState(false);

  const [mobile, setMobile] = useState("")
  const [giftCardId, setGiftCardId] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [otp_, setOtp_] = useState("");

  const [otp_type, setOtpType] = useState("");
  const [showotp, setShowotp] = useState(true);

  const [transaction_id, setTransaction_id] = useState("");


  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const navigate = useNavigate();

  const [showMobile, setShowMobile] = useState(true);
  const [showCards, setShowCards] = useState(false);
  // const [showConfirm, setShowConfirm] = useState(false);
  const [showPaystatus, setShowPaystatus] = useState(false);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [debitCards, setDebitCards] = useState([]);
  const [creditCards, setCreditCards] = useState([]);
  const [paythrough, setPaythrough] = useState('');
  const [cvv, setCvv] = useState('');



  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };


  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);

  };

  const setLoader1 = (val) => {
    setloading1(val);
    setoverlay1(val);
  };

  let toastAttr = {
    theme: "colored",
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const toggleModal = (close) => {
    // alert(close)

    setShowModal(!showModal);
    setOtp_("");
  };


  const verifyOTP = (e) => {
    console.log(
      document.getElementById("otp").value,
      "document.getElementById('otp')"
    );
    let data = {
      mobile: mobile,
      otp_type: otp_type,
      transaction_id: transaction_id,
      otp: document.getElementById("otp").value,
    };
    setLoader1(true)
    axios({
      url: service.payit_linkwallet,
      method: "post",
      data: data,
      headers: { Authorization: tokenKey },


    })
      .then((res) => {
        console.log(res.data)
        setShowMobile(false)
        setShowCards(true)
        setAvailableBalance(res.data.availableBalance)
        var credit_cards = []
        var debit_cards = []
        var cards = res.data.availableCards
        if (cards && cards.length > 0) {
          for (var card of cards) {
            if (card.type == 'CREDIT') {
              credit_cards.push(card)
            } else {
              debit_cards.push(card)
            }
          }
        }

        if (credit_cards.length == 0) {
          var showcredit = document.getElementById("credit_cards")
          var showcredit_radio = document.getElementById("radioButtonsContainer_credit")

          showcredit.style.display = 'none'
          showcredit_radio.style.display = 'none'

        } else {
          setCreditCards(credit_cards)
        }
        if (debit_cards.length == 0) {
          var showdebit = document.getElementById("debit_cards")
          var showdebit_radio = document.getElementById("radioButtonsContainer_debit")
          showdebit.style.display = 'none'
          showdebit_radio.style.display = 'none'
        } else {
          setDebitCards(debit_cards)
        }
        // if (otp_type == "login_verify") {
        //   if (res.data.user_type === "internal") {
        //     toast.success("Verified successfully", toastAttr);
        //     // setTimeout(() => {
        //     //   navigate("/admin/dashboard");
        //     // }, 1000);
        //   }
        // } 
        setMobile('')
        setShowModal(false);
        setLoader1(false);
      })
      .catch((err) => {
        if (err.response.status == 400) {
          setShow(true);
          setBody(err.response.data.message);
        } else if (err.response.status == 500) {
          setShow(true);
          setBody("Server Error!");
        } else if (err.response.status == 401) {
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader1(false);
      });
  };

  function sendOTP(e) {

    let url = service.proceed_payitcard;

    console.log("-----------");


    setLoader(true)
    axios({
      url: url,
      method: "post",
      data: { "mobileno": mobile, "giftcard_topup_id": giftCardId },
      headers: { Authorization: tokenKey },

    }).then((res) => {
      // toggleModal();
      setLoader(false);
      // setShowotp(true);
      setShowModal(true);
      setTransaction_id(res.data.transaction_id)
      // OTPInput()
      // setOtpType("login_verify");
      return;
    }).catch((err) => {
      if (err.response.status == 400) {
        setShow(true);
        setBody(err.response.data.message);
      } else if (err.response.status == 500) {
        setShow(true);
        console.log(err.response)
        setBody(err.response?.data);
      } else if (err.response.status == 401) {
        setShow(true);
        setBody(err.response.data.detail);
      }
      setLoader(false)
    });

  }

  function handleDebit(e) {
    setPaythrough(e.target.value)
    var debitcvv = document.getElementById('debitcvv')
    debitcvv.style.display = 'block'
  }

  function handleCredit(e) {
    setPaythrough(e.target.value)
    var creditcvv = document.getElementById('creditcvv')
    creditcvv.style.display = 'block'
  }

  function proceedPayment() {

  

    let data = {
      mobile: mobile,
      transaction_id: transaction_id,
      amount:1000
    };
    setLoader1(true)
    axios({
      url: service.payit_capturepayment,
      method: "post",
      data: data,
      headers: { Authorization: tokenKey },


    })
      .then((res) => {
        console.log(res.data)
        setShowCards(false)
        setShowPaystatus(true)
        
        setLoader1(false);
        return navigate("/responsepayit", {
          state: {
            card_no: '123456',
            ref_no: 'jbfvuebfin',
            status:'Payment Success'
          },
        });
      })
      .catch((err) => {
        if (err.response.status == 400) {
          setShow(true);
          setBody(err.response.data.message);
        } else if (err.response.status == 500) {
          setShow(true);
          setBody("Server Error!");
        } else if (err.response.status == 401) {
          setShow(true);
          setBody(err.response.data.detail);
        }
        setLoader1(false);
      });

  }

  const EnterOTP = (props) => {

    // if (showotp == true) {
    return (
      <>
        <div className="d-flex justify-content-center mt-5 fw-bold">
          Enter OTP
        </div>
        <div className="d-flex justify-content-center">

          <div className='col-md-3'>
            <input
              className="mt-3 form-control text-center"
              id="otp"
              maxLength={6}
              type="text"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <button className="btn btn-link" onClick={sendOTP}>
            Resend OTP
          </button>
        </div>
      </>
    );
    //   } else {
    //     return (
    //       <div className="d-flex justify-content-center mt-3 fw-bold">
    //         <button
    //           className="btn btn-success"
    //           onClick={sendOTP}
    //           disabled={!mobile}
    //         >
    //           Send OTP
    //         </button>
    //       </div>
    //     );
    //   }
  };

  const ShowCardContent = (props) => {

    if (showMobile) {
      return (
        <>
          {paythrough}
          <input
            type="number"
            id="mobile"
            className=" form-control mt-5 fs-5"
            placeholder="Mobile number"
            value={mobile}
            onChange={e => setMobile(e.target.value)}
          />

          <button
            id="mobile"
            className="btn btn-primary form-control my-5 fs-5 text-white"
            onClick={sendOTP}
            disabled={!mobile}
          >
            Send OTP
          </button>
        </>
      )
    } else if (showCards) {
      return (
        <>
          <div className='bg-dark-subtle  rounded-1'>
            <label className='ms-2 text-black-75'>Payit wallet</label>
          </div>
          <div className='mt-2 ms-3'>
            <input className='mt-1 form-check-input' name='pay_through' type='radio' id='wallet' value={'wallet'} onChange={e => setPaythrough(e.target.value)} checked /> <label for='wallet'>{availableBalance}</label>
          </div>
          <div className='bg-dark-subtle  rounded-1 mt-3' id='debit_cards'>
            <label className='ms-2 text-black-75'>Debit Cards</label>
          </div>
          <div className='mt-2 ms-3' id='radioButtonsContainer_debit'>
            {debitCards.map((dcard,index) => (
              <>
                <input className='mt-1 form-check-input' name='pay_through' type='radio' value={dcard.id} onChange={e => handleDebit(e)} id={'deb'+index.toString()}/> <label for={'deb'+index.toString()}>{dcard.number}</label>
                <input id='debitcvv' style={{ display: 'none' }} onChange={e => setCvv(e.target.value)} type='text' className=" form-control mt-2 fs-5" />

              </>
            ))}
            {/* <input className='mt-1 form-check-input' name='pay_through' type='radio'  value={'o'} onChange={e => handleDebit(e)} id='hdwbhc'/> <label for={'hdwbhc'}>hdwbhc</label>
            <input id='debitcvv' style={{display:'none'}} type='text' className=" form-control mt-2 fs-5"/> */}
          </div>

          <div className='bg-dark-subtle  rounded-1 mt-3' id='credit_cards'>
            <label className='ms-2 text-black-75'>Credit Cards</label>
          </div>
          <div className='mt-2 ms-3' id='radioButtonsContainer_credit'>
            {creditCards.map((ccard,index) => (
              <>
                <input className='mt-1 form-check-input' name='pay_through' type='radio' value={ccard.id} onChange={e => handleCredit(e)} id={'deb'+index.toString()} /> <label for={'deb'+index.toString()}>{ccard.number}</label>
                <input id='creditcvv' style={{ display: 'none' }} onChange={e => setCvv(e.target.value)} type='text' className=" form-control mt-2 fs-5" />
              </>
            ))}
          </div>



          <div>
            <button className="btn btn-primary form-control my-5 fs-5 text-white" onClick={proceedPayment}>
              Proceed
            </button>
          </div>

        </>
      )

    }
    else if (showPaystatus) {
      return (
        <>

        </>
      )

    }
    else {
      return ('')
    }

  }

  useEffect(() => {
    console.log(location);
    let giftcard_topup_id = location.state ? location.state.returndata : null;
    console.log(giftcard_topup_id);
    setGiftCardId(giftcard_topup_id)
  }, [])



  return (
    <Loader loading={loading} overlay={overlay}>

      <Container>
        <Card className="payit__card">
          <Card.Body>
            <ShowCardContent />
          </Card.Body>
        </Card>
      </Container>

      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modalotpheader">
          <div className="d-flex justify-content-between">
            <span className="fw-bold fs-5">Verify OTP</span>
          </div>
          <button
            className="btn btn-link"
            onClick={() => toggleModal("close")}
          >
            <CloseButton variant="white" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Loader loading={loading1} overlay={overlay1}>
            <EnterOTP />
          </Loader>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => toggleModal("close")}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={verifyOTP}>
            Verify
          </button>
        </Modal.Footer>
      </Modal>

      <CustomModal show={show} body={Body} handleClose={handleClose} />

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Loader>
  )
}

export default ProceedPayitCard
