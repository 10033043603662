import React, { useState, useEffect, useMemo } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import { Button, InputGroup } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import service from "../../../../services/service";
import DataTable from "react-data-table-component";
import DateRangePickerWrapper from "../../../../components/DateRangePickerWrapper";
import moment from "moment";
import Select from "react-select";
import { AiOutlineSearch } from "react-icons/ai";
import styled from "styled-components";
import Loader from "../../../../components/Loader";
import CustomModal from "../../../../components/CustomModal";

const DateDiv = styled.div`
border: 1px solid #d6d6d6;
border-radius: 5px;
width: 100%;
padding: 8px;
background:white;

  &:active {
    border: 1px solid black;
  }
`;

const ApiLog = () => {
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  let navigate = useNavigate();
  let location = useLocation();
  let tokenKey = `Bearer ${sessionStorage.getItem("tokenkey")}`;

  console.log(tokenKey);
  let breadcrumbData = [
    {
      title: "Dashboard",
      href: "/admin/dashboard",
    },
    {
      title: "API Log",
      href: "#",
    },
  ];
  const [tableData, settableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState("");
  const [pending, setPending] = useState(true);

  const [show, setShow] = useState(false);
  const [Body, setBody] = useState("");

  const handleClose = (e) => {
    console.log(e, "from modal");
    setShow(e);
  };

  const [dateRange, setdateRange] = useState({
    start_date: null,
    end_date: null,
  });
  const [dateLabel, setdateLabel] = useState("");

  const deleteRow = (row) => {
    console.log("delete", row.id);
  };

  const [countryList, setCountryList] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);

  const ApiLogList = () => {
    setLoader(true);
    axios({
      url: service.activities_log_list,
      method: "get",
      headers: { Authorization: tokenKey },
      params: {
        page: page,
        size: perPage,
        search: search,
        from_date: dateRange.start_date,
        to_date: dateRange.end_date,
        country_id: selectedCountry && selectedCountry.value,
        ordering: sorting,
      },
    })
      .then((res) => {
        settableData(res.data.results);
        setTotalRows(res.data.count);
        setPending(false);
        console.log(res);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
  };

  const handleCountryList = () => {
    axios
      .get(service.country_details)
      .then((res) => {
        let countrylist = [];
        res.data.map((obj) => {
          countrylist.push({
            value: obj.id,
            label: obj.country_name,
          });
        });

        console.log(countrylist, res.data);
        setCountryList(countrylist);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
  };

  const clearDate = () => {
    setdateRange({});
    setdateLabel("");
  };
  const handleDateRange = (event, picker) => {
    console.log(picker, "PPPPPPPPPPPPPPPPPPPPPP");
    setdateRange({
      start_date: moment(picker.startDate._d).format("YYYY-MM-DD"),
      end_date: moment(picker.endDate._d).format("YYYY-MM-DD"),
    });
    const fromDate = moment(picker.startDate._d).format("DD/MM/YYYY");
    const toDate = moment(picker.endDate._d).format("DD/MM/YYYY");
    setdateLabel(fromDate + " - " + toDate);
    // sessionStorage.setItem("date", "Date : " + fromDate + " to " + toDate);
    // sessionStorage.setItem("fromDate", fromDate);
    // sessionStorage.setItem("toDate", toDate);
  };

  useEffect(() => {
    ApiLogList();
    if (countryList.length <= 0) {
      handleCountryList();
    }
  }, [search, page, perPage, dateRange, sorting, selectedCountry]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "18px",
        justifyContent: "flex-start",
        // paddingLeft: '0px', // override the cell padding for head cells
        // paddingRight: '0px',
      },
    },
    rows: {
      style: {
        fontSize: "18px",
        minHeight: "52px",
      },
    },
    // headCells: {
    //   style: {
    //     textAlign: 'center', // Center-align column names
    //     fontSize: '50px',

    //   },
    // },
  };

  const columns = [
    {
      id: 1,
      name: "API Name",
      sorting_name: "api_name",
      selector: (row) => row.api_name.toString()

      .replace(/_+/g, ' ')

      .toLowerCase()

      .replace(/\b\w/g, (s) => s.toUpperCase()),
      sortable: true,
      reorder: true,
      width:"auto"

    },

    {
      id: 2,
      name: "Request Time",
      sorting_name: "request_time",

      selector: (row) => row.request_time,
      sortable: true,
      // right: true,
      reorder: true,
      width:"auto"

    },

    {
      id: 3,
      name: "Requested By",
      sorting_name: "created_by_id",

      selector: (row) => row.created_by_id?.email,
      sortable: true,
      // right: true,
      reorder: true,
      width:"auto"

    },
    {
      id: 4,
      name: "Mobile",
      sorting_name:"created_by_id",

      selector: (row) => row.created_by_id?.mobile,
      sortable: true,
      // right: true,
      reorder: true,
      width:"auto"

    },

    {
      id: 5,
      name: "Status",
      sorting_name: "log_status",

      selector: (row) => row.log_status.toString()

      .replace(/_+/g, ' ')

      .toLowerCase()

      .replace(/\b\w/g, (s) => s.toUpperCase()),
      sortable: true,
      // right: true,
      reorder: true,
      width:"auto"

    },
    //   {
    //     id: 5,
    //     name:"Is Email Verified",
    //     // style: {
    //     //   minWidth: "150px",
    //     // },
    //   cell: (row) => (
    //       <>

    //     <input type='checkbox' readOnly checked={row.is_email_verified} className='btn btn-danger m-2'/>
    //     </>
    //   ),

    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,

    // },

    {
      name: "Actions",

      cell: (row) => (
        <>
          <span
            onClick={() => {
              console.log(row.id);
              return navigate("/admin/logs/apilogForm", {
                state: {
                  id: row.id,
                  editMode: true,
                },
              });
            }}
            className="btn btn-grad-primary"
          >
            View
          </span>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];


  const handlePageChange = async(page) => {
    // setPage(page);
console.log(perPage,"//",page);
    await axios({
      url: service.activities_log_list,
      method: "get",
      headers: { Authorization: tokenKey },
      params: {
        page: page,
        size: perPage,
        search: search,
        from_date: dateRange.start_date,
        to_date: dateRange.end_date,
        country_id: selectedCountry && selectedCountry.value,
        ordering: sorting,
      },
    })
      .then((res) => {
        settableData(res.data.results);
        setTotalRows(res.data.count);
        setPending(false);
        console.log(res);
        setLoader(false)

      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log(newPerPage,page)
    setLoader(true)

    await axios({
      url: service.activities_log_list,
      method: "get",
      headers: { Authorization: tokenKey },
      params: {
        page: page,
        size: newPerPage,
        search: search,
        from_date: dateRange.start_date,
        to_date: dateRange.end_date,
        country_id: selectedCountry && selectedCountry.value,
        ordering: sorting,
      },
    })
      .then((res) => {
        settableData(res.data.results);
        setPerPage(newPerPage);
        setPending(false);
        console.log(res);
        setLoader(false)

      })
      .catch((err) => {
        setLoader(false);

        if (err?.response?.status === 401) {
          window.location.href = "/";
        }
        if (err?.response?.status === 500) {
          setShow(true)
          setBody(err.response.data)
        } else if (err?.response?.status === 400) {
          setShow(true)
          setBody(err.response.data.message)
        } else {
          setShow(true)
          setBody(err.response.data.message)
        }
      });
  };
  
  const handleSort = async (column, sortDirection) => {
    
    console.log(column,sortDirection);
    if(sortDirection == 'asc'){
      setSorting(column.sorting_name);
    } else {
      setSorting("-" + column.sorting_name);
    }
  };
  return (

    <div className="px-5">
      <div>
        <div className="pageNav">
          <div className="bold-text">
            {/* <Breadcrumbs data={breadcrumbData} /> */}
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
          <Card className="country__list">
            <>
              <Row>
                <Col md={4}>
                  <h3 className="pt-3 px-3 text-primary">API LOGS</h3>
                </Col>
                <Col md={4}>
                  <div className="pt-3">

            <Breadcrumbs data={breadcrumbData} />
                  </div>

                </Col>
                <Col md={4}></Col>
              </Row>
              <hr />
              <Row>
                <Col md={3}>
                  <div className="mx-3">
                    <Select
                      onChange={setSelectedCountry}
                      value={selectedCountry}
                      options={countryList}
                      placeholder="Select Country"
                      isSearchable={false}
                      style={{ width: "80%" }}
                      isClearable={true}
                    />
                  </div>
                </Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3} className="d-flex">
                  <DateRangePickerWrapper
                    alwaysShowCalendars={true}
                    applyButtonClasses="test"
                    onEvent={handleDateRange}
                  >
                    <DateDiv>
                      {dateLabel ? (
                        dateLabel
                      ) : (
                        <span style={{ color: "#d6d6d6" }}>
                          DD/MM/YYYY - DD/MM/YYYY
                        </span>
                      )}
                    </DateDiv>
                  </DateRangePickerWrapper>
                  <div
                    className="btn-link text-primary"
                    style={{ padding: "10px", cursor: "pointer" }}
                    onClick={clearDate}
                  >
                    clear
                  </div>
                </Col>
              </Row>
            </>
            <hr/>
            <CardBody>
              <Row className="d-flex">
                <Col md={4}>
                  <input
                    type="text"
                    placeholder="Search"
                    onBlur={(e) => setSearch(e.target.value)}
                    className="form-control"
                    style={{ width: "70%" }}
                  />
                </Col>
                <Col md={6}></Col>
                <Col md={2}>
                  <InputGroup className="mb-3 w-100">
                    <Form.Control
                      placeholder="Go to page"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      id="searchPage"
                    />
                    <Button
                      variant="outline-primary"
                      id="button-addon2"
                      onClick={() => {
                        handlePageChange(document.getElementById("searchPage").value);

                      }}
                    >
                      go
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <br />
     <Loader loading={loading} overlay={overlay}>

              <Row>
                <DataTable
                  columns={columns}
                  data={tableData}
                  defaultSortFieldId={1}
                  customStyles={customStyles}
                  progressPending={pending}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  onSort={handleSort}
                  sortServer
                  
                />
              </Row>
              </Loader>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ApiLog;
